body {
  background: #000;
}

.window {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  --width: 1920px;
  --height: 930px;
  position: relative;
  overflow: hidden;
  width: var(--width);
  height: var(--height);
  transform: scale(1);
  flex-shrink: 0;
  background: #fff;
}

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  flex-shrink: 0;
  background: #fff;
  width: var(--width);
  height: var(--height);
  opacity: 0;
  transition: opacity 0.5s;
}

.page--active {
  z-index: 1;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  body {
    background: initial;
  }
  .window {
    position: static;
    display: block;
    height: auto;
    overflow: initial;
    background: none;
  }
  .container {
    position: static;
    display: block;
    width: 100%;
    height: auto;
    overflow: initial;
  }
  .wrapper {
    display: block;
    height: auto;
    overflow: hidden;
  }
  .page {
    width: 100%;
    min-width: 320px;
    height: auto;
    min-height: 100vh;
    transition: 0s!important;
  }
  .page--active {
    position: static;
  }
}
