.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 180px;
  padding: 0 40px;
  transition: transform 0.5s var(--timing-function1), background 0.5s, box-shadow 0.5s;
  transform: translate(0, -100%);
  pointer-events: initial;
  will-change: transform;
  color: #231815;
}

.header__show {
  transform: translate(0, 0);
}

.header .logo {
  position: relative;
  display: block;
  width: 194px;
}

.header .logo img {transition: opacity .5s;}
.header .logo .color {position: absolute; top: 0; left: 0; width: 100%; opacity: 0;}

.gnb {
  display: flex;
  height: 100%;
  margin-right: auto;
  transition: transform .5s var(--timing-function1);
}

.gnb__item {
  color: #fff;
  font-family: var(--font-sub1);
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: color 0.4s;
}

.gnb__item > a {display: flex; align-items: center; justify-content: center; height: 100%; padding-inline: 22px;}

.gnb__item:not(.active):hover > a {
  opacity: 0.5;
}

.gnb__item.active {
  color: var(--primary);
}

.submenu {position: absolute; top: 100%; left: 0; display: flex; justify-content: center; background: #fff; width: 100%; padding-block: 20px; border-top: 1px solid #dadada; display: flex; font-family: var(--font-main); opacity: 0; transition: opacity .5s; pointer-events: none;}
.submenu:has(.depth2) {padding-block: 20px 80px;}
.gnb__item:hover .submenu {opacity: 1; pointer-events: initial;}
.submenu .depth1 {display: flex;}
.submenu .depth1__item {width: 250px; text-align: center; color: #000;}
.submenu .depth1__item > a {position: relative; display: block; font-size: 18px; font-weight: 700; width: 100%; height: 2em; display: flex; align-items: center; justify-content: center; line-height: 1;}
.submenu .depth1__item:hover > a {color: var(--primary);}
.submenu .depth1__item:not(:last-child) > a::after {position: absolute; top: 50%; right: 0; transform: translate(0, -50%); width: 1px; height: 80%; background: #dadada; content: '';}
.submenu .depth1__item .depth2 {padding-top: 15px; color: #b3b3b3; font-size: 16px;}
.submenu .depth1__item .depth2 > li a {display: block; padding: 7px; text-transform: none;}
.submenu .depth1__item .depth2 > li a:hover {color: var(--primary);}

.header__etc {
  display: flex;
  align-items: center;
  height: 100%;
}

.header__etc .lang {display: flex; flex-direction: column; gap: 0; padding: 0 40px 0 30px; height: 100%; justify-content: center;}
.header__etc .lang a {padding: 5px; font-size: 18px; color: #fff;}
.header__etc .lang .active a {color: var(--primary); font-weight: 700;}

.header__etc .inq_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 0 50px;
  background: linear-gradient(to right, #00abd2, #0087be);
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.header__etc .inq_btn:hover {
  opacity: 0.8;
}

.header__etc .ctrs {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header__etc .ctrs .ctrs__btn {
  display: flex;
}

.header__etc .ctrs .ctrs__btn:hover {
  opacity: 0.6;
}

.header__etc .ctrs .ctrs__idx {
  width: 23px;
  height: 18px;
  flex-direction: column;
  justify-content: space-between;
}

.header__etc .ctrs .ctrs__idx .bar {
  width: 100%;
  height: 2px;
  background: #808080;
}

.header__etc .ctrs .ctrs__autoplay {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #808080;
  align-items: center;
  justify-content: center;
}

.header__etc .ctrs .ctrs__autoplay::before,
.header__etc .ctrs .ctrs__autoplay::after {
  display: block;
  content: '';
}

.header__etc .ctrs .ctrs__autoplay::before {
  width: 0;
  height: 0;
  border-left: 8px solid #808080;
  border-block: 6px solid transparent;
  transform: translate(12.5%);
}

.header__etc .ctrs .ctrs__autoplay:global(.play) {gap: 2px;}
.header__etc .ctrs .ctrs__autoplay:global(.play)::after,
.header__etc .ctrs .ctrs__autoplay:global(.play)::before {
  border: none;
  transform: none;
  width: 3px; height: 50%;
  background: #808080;
}

.controller {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  pointer-events: none;
  transition: transform .7s var(--timing-function1);
}
.controller .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  pointer-events: initial;
}
.controller .btn:hover {
  opacity: 0.5;
}
.controller .btn .icon {
  width: 20px;
  height: 20px;
  border-color: #808080;
}
.controller .idx {display: none;}
:global(.ct-page--1) {
  .controller .btn.prev {opacity: .5; pointer-events: none;}
}
:global(.ct-pageId--lastCover) {
  .controller .btn.next {opacity: .5; pointer-events: none;}
}

.header:has(.gnb:hover),
.header.theme--dark {
  background: #fff;
  box-shadow: 0 0 20px rgb(0, 0, 0, 0.15);
}

.header:has(.gnb:hover) .logo img,
.header.theme--dark .logo img {opacity: 0;}
.header:has(.gnb:hover) .logo .color,
.header.theme--dark .logo .color {opacity: 1;}

.header:has(.gnb:hover) .gnb__item:not(.active),
.header.theme--dark .gnb__item:not(.active) {color: inherit;}

.header:has(.gnb:hover) .header__etc .lang > li:not(.active) a,
.header.theme--dark .header__etc .lang > li:not(.active) a {color: inherit;}

:global(.ct-navState--hide) .controller {transform: translate(0, 100%);}

:global(.ct-pageId--index) .gnb,
:global(.ct-pageId--cover) .gnb {transform: translate(0, -300%);}
:global(.ct-pageId--index) .header__etc .lang > li:not(.active) a,
:global(.ct-pageId--cover) .header__etc .lang > li:not(.active) a {color: inherit;}

@media screen and (min-width: 768px) {
  :global(.ct-lang--en) .submenu .depth1__item > a {font-size: 16px;}
}

@media screen and (max-width: 767px) {
  .container {position: static;}
  .m_hide {display: none;}
  .header {position: fixed; top: 0; left: 0; width: 100%; z-index: 9999; transform: none!important; background: #fff; padding: 0 15px; height: 50px; gap: 0;}
  .header .logo {flex-shrink: 0; width: 120px;}
  .header .logo img {display: none;}
  .header .logo img.color {position: static; display: block; opacity: 1;}
  .gnb {display: none;}
  .header__etc .ctrs {display: none;}
  .header__etc .inq_btn {order: 1; font-size: 16px; padding: 10px 20px; margin-right: -15px;}
  .header__etc .lang {order: 0; padding: 0 15px; flex-direction: row; align-items: center;}
  .header__etc .lang > li a {font-size: 16px;}
  .header__etc .lang > li:not(.active) a {color: inherit!important;}

  .controller {position: fixed; bottom: 0; left: 0; width: 100%; transform: none!important; background: #fff; pointer-events: initial; padding: 10px 15px;}
  .controller .btn {padding: 15px; border: 1px solid #adadad; border-radius: 10px;}
  .controller .btn .icon {width: 12px; height: 12px; border-color: #ADADAD;}
  .controller .btn.prev .icon {margin-right: -3px;}
  .controller .btn.next .icon {margin-left: -3px;}
  .controller .idx {display: flex; flex-direction: column; justify-content: space-between; width: 40px; height: auto; padding: 10px 0; border: none; gap: 8px;}
  .controller .idx span {width: 100%; height: 1px; background: #adadad;}
}

:global(.ct-lang--en) .submenu.auto .depth1__item {width: auto;}
:global(.ct-lang--en) .submenu.auto .depth1__item > a {padding-inline: 30px;}
