:root {
  --font-main: 'Pretendard', '맑은 고딕', arial, sans-serif;
  --font-sub1: 'Orbitron', 'Pretendard', '맑은 고딕', arial, sans-serif;
  --primary: #0081c2;
  --primary-light: #69bdff;
  --primary-dark: #005191;
  --red: #f4362b;
  --green: #66bf97;
  --purple: #c08dbd;
  --page-padding: 162px 100px 50px 116px;
  --timing-function1: cubic-bezier(0.85, 0.14, 0.29, 0.99);
  --timing-function2: cubic-bezier(0.71, 0.45, 0.36, 1.31);
  --timing-function3: cubic-bezier(0.85, 0.14, 0.29, 0.99);
  --animate-ease: var(--timing-function1);
}

body {
  color: #3c3d3d;
  font-family: var(--font-main);
  font-weight: 500;
}

.fc--blue {color: var(--primary-dark)!important;}

.ct-page {
  width: 100%;
  height: 100%;
  padding: var(--page-padding);
}

.ct-page .pg-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.ct-page .pg-bg :where(div, img, video) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ct-page .pg-content {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  :root {
    --page-padding: 100px 15px 130px 15px;
  }

  .ct-page {
    width: 100%;
    height: auto;
    min-height: 100vh;
  }
}
